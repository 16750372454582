<template>
  <div class="edu-frame" style="background-color: rgb(239 239 239);">
    <edu-top-header></edu-top-header>
    <div style="width: 100%;display: flex;justify-content: center;align-items: center;margin-top: 60%;">
      <van-loading size="24px">加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import EduTopHeader from "@/components/VolunteerHeader";
import { Loading } from 'vant';
import CustomerApi from "@/api/CustomerApi";
import Tools from "@/api/Tools";
export default {
  components: {
    EduTopHeader,
    VanLoading: Loading,
  },
  methods: {
    login() {
      var openId = this.$route.query.openid
      if (openId != undefined && openId != null && openId != '') {
        sessionStorage.setItem('openId', openId)

        this.loading = true
        CustomerApi.loginByOfficalOpenId({ weixinOpenId: openId, loginType: null, identityType: 'volunteer' }).then(response=>{
          // console.log(response.res)
          this.loading = false
          if (response.res != null) {
            Tools.setCurCust(response.res)
            Tools.setToken(response.res.token)

            this.$router.replace({name: 'vmain'})
          } else {
            this.$router.push({ name: 'vlogin' })
          }

        }).catch(()=>{ this.loading = false })



      } else {
        this.$router.replace({ name: 'vlogin' })
      }
    }
  },
  mounted() {
    document.title = '粤美·巾帼故事妈妈线上平台'
    this.login()
  }
}
</script>
